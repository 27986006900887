var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [this.item.length == 0 ? _c('div', [_c('b-jumbotron', {
    staticClass: "bg-transparent",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Oops, Data Not Found")];
      },
      proxy: true
    }, {
      key: "lead",
      fn: function () {
        return [_vm._v(" Items for Audiobooks Not Found ")];
      },
      proxy: true
    }], null, false, 3483749332)
  })], 1) : _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: '/audio'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('div', {
    staticClass: "row mt-4 create-new"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Uploaded Chapter List " + _vm._s(_vm.item.name))])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "card-title"
  }, [_vm._v(" Chapter Count : " + _vm._s(_vm.audioItem.length))]), _c('h5', {
    staticClass: "card-title"
  }, [_vm._v(" Edition Code : " + _vm._s(_vm.item.edition_code))]), _c('h5', {
    staticClass: "card-title"
  }, [_vm._v(" Status Items : "), _vm.item_status_item == _vm.itemStatus.Unpublished ? _c('span', {
    staticClass: "badge badge-danger"
  }, [_vm._v(" " + _vm._s(_vm.item_status_item) + " ")]) : _vm.item_status_item == _vm.itemStatus.Published ? _c('span', {
    staticClass: "badge badge-primary"
  }, [_vm._v(" " + _vm._s(_vm.item_status_item) + " ")]) : _vm.item_status_item == _vm.itemStatus.Uploaded ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(" " + _vm._s(_vm.item_status_item) + " ")]) : _c('span', {
    staticClass: "badge badge-info p-1"
  }, [_vm._v(" " + _vm._s(_vm.item_status_item) + " ")])]), _vm.audioItem.length == 0 ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('b-button', {
    staticClass: "mr-3 text-white",
    attrs: {
      "type": "button",
      "variant": "success"
    },
    on: {
      "click": _vm.addChapter
    }
  }, [_vm._v("Upload New Chapter")]), _c('b-button', {
    staticClass: "mr-3 text-white",
    attrs: {
      "type": "button",
      "variant": "success"
    },
    on: {
      "click": _vm.bulkChapter
    }
  }, [_vm._v("Bulk Upload New Chapter")])], 1)])]) : _c('div', [_vm.item_status_item == 'ready for upload' && _vm.audiobooks_chapter.length > 0 ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('b-button', {
    staticClass: "mr-3 text-white",
    attrs: {
      "variant": "success",
      "type": "button"
    },
    on: {
      "click": _vm.updateStatus
    }
  }, [_vm._v("Process")]), _c('b-button', {
    staticClass: "mr-3 text-white",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": _vm.bulkChapter
    }
  }, [_vm._v("Bulk Upload")])], 1)]), _c('div', [_c('b-row', {
    staticClass: "px-5 py-2",
    attrs: {
      "align-h": "end"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": function ($event) {
        return _vm.addChapter();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1)], 1)]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "xl",
      "show-empty": "",
      "hover": "",
      "items": _vm.audiobooks_chapter,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(chapter)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.chapter) + " ")];
      }
    }, {
      key: "cell(isActive)",
      fn: function (row) {
        return [_c('div', [row.item.isActive ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(showSample)",
      fn: function (row) {
        return [_c('div', [row.item.showSample ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-modal', {
          attrs: {
            "size": "lg",
            "id": ("modal-play-audio-" + (row.item.id)),
            "centered": "",
            "hide-header": "",
            "hide-footer": "",
            "title": _vm.titleModal
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', [_c('audioPlayer', {
          attrs: {
            "autoPlay": true,
            "file": _vm.file_chapter_url,
            "loop": false,
            "isDownload": false,
            "title": _vm.titleModal,
            "itemAudio": row.item
          }
        })], 1)], 1)], 1), _c('span', [_c('b-button', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.playAudioFromUrl(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-play-circle"
        })])], 1), _c('span', [_vm.item_status_item == 'ready for upload' ? _c('b-button', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.editChapter(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })]) : _vm._e()], 1), _c('span', [_vm.item_status_item == 'ready for upload' ? _c('b-button', {
          attrs: {
            "variant": "default"
          },
          on: {
            "click": function ($event) {
              return _vm.removeChapter(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash warning"
        })]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1)])])])])])]), _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "modal-edit-chapter",
      "centered": "",
      "title": _vm.chapter.header + " Chapter (" + _vm.chapter.chapter + ")",
      "hide-footer": ""
    }
  }, [_c('b-form', {
    on: {
      "submit": _vm.onSubmitChapter
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "t_chapter",
      "label": "Title Chapter"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "t_chapter",
      "type": "text",
      "placeholder": "Enter Title",
      "required": ""
    },
    model: {
      value: _vm.chapter.title,
      callback: function ($$v) {
        _vm.$set(_vm.chapter, "title", $$v);
      },
      expression: "chapter.title"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.chapter.is_sample,
      callback: function ($$v) {
        _vm.$set(_vm.chapter, "is_sample", $$v);
      },
      expression: "chapter.is_sample"
    }
  }, [_vm._v("Show Sample ")])], 1)], 1)], 1), _vm.chapter.is_sample ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "minimum_level",
      "label": "Minimum Level"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "minimum_level",
      "type": "number",
      "placeholder": "Enter Title"
    },
    model: {
      value: _vm.chapter.minimum_level,
      callback: function ($$v) {
        _vm.$set(_vm.chapter, "minimum_level", $$v);
      },
      expression: "chapter.minimum_level"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "t_from",
      "label": "From (minutes)"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "t_from",
      "type": "number",
      "placeholder": "Enter Title"
    },
    model: {
      value: _vm.chapter.time_from,
      callback: function ($$v) {
        _vm.$set(_vm.chapter, "time_from", $$v);
      },
      expression: "chapter.time_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "t_to",
      "label": "To (minutes)"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "t_to",
      "type": "number",
      "placeholder": "Enter Title"
    },
    model: {
      value: _vm.chapter.time_to,
      callback: function ($$v) {
        _vm.$set(_vm.chapter, "time_to", $$v);
      },
      expression: "chapter.time_to"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File Content (max: 150 mb) "
    }
  }, [_c('b-form-file', {
    attrs: {
      "state": Boolean(_vm.chapter.is_edit),
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here...",
      "accept": ".mp3, .wav",
      "required": !_vm.chapter.is_edit
    },
    model: {
      value: _vm.file_chapter,
      callback: function ($$v) {
        _vm.file_chapter = $$v;
      },
      expression: "file_chapter"
    }
  })], 1)], 1), _vm.file_chapter != null ? _c('div', [_c('b-col', [_vm._v(" File Size: " + _vm._s(_vm._f("convertSize")(_vm.file_chapter ? _vm.file_chapter.size : 0)) + " MB ")]), _vm.chapter.is_edit ? _c('b-col', [_vm._v(" Current File Size: " + _vm._s(_vm.chapter.current_size) + " ")]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('audio', {
    staticClass: "w-100",
    attrs: {
      "id": "sound",
      "controls": "",
      "src": _vm.file_chapter_view
    }
  })])], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('b-button', {
    staticClass: "text-white",
    attrs: {
      "variant": "success",
      "type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.chapter.is_edit ? 'Edit Chapter' : 'Add Chapter') + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }