<template>
<div>
      <div v-if='this.item.length == 0'>
        <b-jumbotron class='bg-transparent'>
          <template #header>Oops, Data Not Found</template>
          <template #lead>
           Items for Audiobooks Not Found
          </template>
        </b-jumbotron>
        </div>
      <div v-else>
        <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner small type="grow" variant="secondary"></b-spinner>
            <b-spinner type="grow" variant="dark"></b-spinner>
            <b-spinner small type="grow" variant="secondary"></b-spinner>
          </div>
        </template>
        <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/audio'}">
          <em class="fa fa-backward btn-sm btn-dark "></em> Back
        </router-link>
        <div class="row mt-4 create-new">
          <div class="col-md-12">
            <div class="card">
              <div class="bg-success p-3">
                <h5 class="card-title mb-0 font-weight-bold">Uploaded Chapter List {{item.name}}</h5>
              </div>
              <div class="card-body">
                <div class="card" >
                  <div class="card-body">
                    <h5 class="card-title">  Chapter Count : {{audioItem.length}}</h5>
                    <h5 class="card-title">  Edition Code : {{item.edition_code}}</h5>
                    <h5 class="card-title">  Status Items : 
                    <span v-if="item_status_item == itemStatus.Unpublished" class="badge badge-danger">
                      {{ item_status_item}}
                    </span>
                    <span v-else-if="item_status_item == itemStatus.Published" class="badge badge-primary">
                      {{ item_status_item}}
                    </span>
                    <span v-else-if="item_status_item == itemStatus.Uploaded" class="badge badge-warning">
                      {{ item_status_item}}
                    </span>
                    <span v-else class="badge badge-info p-1">
                      {{ item_status_item}}
                    </span>
                    </h5>
                    <div v-if="audioItem.length==0">
                    <div class="row">
                      <div class="col">
                        <b-button class="mr-3 text-white" type="button" variant="success" @click="addChapter">Upload New Chapter</b-button>
                        <b-button class="mr-3 text-white" type="button" variant="success" @click="bulkChapter">Bulk Upload New Chapter</b-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="item_status_item == 'ready for upload' && audiobooks_chapter.length>0">
                      <div class="row">
                        <div class="col">
                          <b-button variant="success"   class="mr-3 text-white" 
                            type="button"  @click="updateStatus">Process</b-button>
                          <b-button variant="primary"  class="mr-3 text-white" 
                            type="button"  @click="bulkChapter">Bulk Upload</b-button>
                        </div>
                      </div>
                      <div>
                        <b-row align-h="end" class="px-5 py-2">
                          <b-button variant="dark" @click="addChapter()">
                            <i class="fa fa-plus"></i>
                          </b-button>
                        </b-row>
                      </div>
                    </div>
                    <b-row>
                      <b-col cols="12">
                        <b-table  responsive="xl" show-empty hover :items="audiobooks_chapter" :fields="fields">
                          <template #cell(chapter)="row">
                            {{ row.item.chapter }}
                          </template>
                          <template #cell(isActive)="row">
                            <div>
                              <span v-if="row.item.isActive" class="badge">&#9989;</span>
                              <span v-else class="badge" style="color:red"> &#10005; </span>
                            </div>
                          </template>
                          <template #cell(showSample)="row">
                            <div>
                              <span v-if="row.item.showSample" class="badge">&#9989;</span>
                              <span v-else class="badge" style="color:red"> &#10005; </span>
                            </div>
                          </template>
                          <template  #cell(actions)="row">
                            <b-modal size="lg" :id="`modal-play-audio-${row.item.id}`" centered hide-header hide-footer :title=titleModal>
                              <b-col cols="12">
                                <b-form-group>
                                  <audioPlayer
                                    :autoPlay=true
                                    :file=file_chapter_url
                                    :loop=false
                                    :isDownload =false
                                    :title= titleModal
                                    :itemAudio = row.item
                                  >
                                  </audioPlayer>
                        
                                </b-form-group>
                              </b-col>
                            </b-modal>
                            <span>
                              <b-button variant="default" @click="playAudioFromUrl(row.item)">
                                <i class="fa fa-play-circle"></i>
                              </b-button>
                            </span>
                            <span>
                              <b-button variant="default" v-if="item_status_item == 'ready for upload'" @click="editChapter(row.item)">
                                <i class="fa fa-edit"></i>
                              </b-button>
                            </span>
                            <span>
                              <b-button variant="default" v-if="item_status_item == 'ready for upload'" @click="removeChapter(row.item)">
                                <i class="fa fa-trash warning"></i>
                              </b-button>
                            </span>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal size="lg" id="modal-edit-chapter" centered :title="chapter.header + ` Chapter (` + chapter.chapter + `)`"
        hide-footer>
        <b-form @submit="onSubmitChapter">
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="t_chapter" label="Title Chapter">
                <b-form-input id="t_chapter" v-model="chapter.title" type="text" placeholder="Enter Title" required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox v-model="chapter.is_sample" :value="true" :unchecked-value="false">Show Sample
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="chapter.is_sample">
            <b-col cols="4">
              <b-form-group label-for="minimum_level" label="Minimum Level">
                <b-form-input id="minimum_level" v-model="chapter.minimum_level" type="number" placeholder="Enter Title">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="t_from" label="From (minutes)">
                <b-form-input id="t_from" v-model="chapter.time_from" type="number" placeholder="Enter Title">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label-for="t_to" label="To (minutes)">
                <b-form-input id="t_to" v-model="chapter.time_to" type="number" placeholder="Enter Title">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="File Content (max: 150 mb) ">
                <b-form-file v-model="file_chapter" :state="Boolean(chapter.is_edit)"
                  placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." accept=".mp3, .wav"
                  :required="!chapter.is_edit">
                </b-form-file>
              </b-form-group>
            </b-col>
            <div v-if="file_chapter!=null">
            <b-col>
              File Size: {{ file_chapter ? file_chapter.size : 0 | convertSize }} MB
            </b-col>
            <b-col v-if="chapter.is_edit">
              Current File Size: {{ chapter.current_size }}
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <audio class="w-100" id="sound" controls  :src="file_chapter_view"></audio>
              </b-form-group>
            </b-col>
          </div>
          </b-row>
          
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-button variant="success" class="text-white" type="submit">
                  {{ chapter.is_edit ? 'Edit Chapter' : 'Add Chapter' }}
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </b-overlay>
  </div>
</div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapState, mapActions } from "vuex";
  import constant from '../../../store/constant';
  import audioPlayer from '../../../components/audioplayer/audioPlayer.vue'; 
  const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return { text: item[0], value: item[1] } })
  
  export default {
    name: "editAudioBookChapter",
    components: {
      audioPlayer,
    },
    data() {
      return {
        form: {
          display_name: "",
          item_status: "",
          release_date: "",
          release_schedule: "",
          is_active: false,
        },
        titleModal: null,
        fields: [
          { key:'No', label: 'No'},
          { key:'title', label: 'Title'},
          { key:'duration', label: 'Durations'},
          { key:'isActive', label: 'Is Active'},
          { key:'showSample', label: 'Show Sample'},
          { key:'size', label: 'Size'},
          { key :'created', label:'Created At'},
          { key :'actions', label:'Actions'},
        ],
        chapter: {
          header: "Edit",
          is_edit: true,
          chapter: null,
          title: null,
          is_sample: false,
          minimum_level: 0,
          time_from: 0,
          time_to: 0,
          current_size: null,
        },
        audiobooks_chapter: [],
        file_chapter: null,
        file_chapter_view: null,
        file_chapter_url: null,
        itemStatusOptions: ITEM_STATUS,
        show: true,
        item_id: null,
        itemStatus: constant.ITEM_STATUS,
        item_status_item:null,
      };
  
    },
    computed: {
      ...mapState({
        isError: (state) => state.audioBooks.isError,
        isLoading: (state) => state.audioBooks.isLoading,
        errorMessage: (state) => state.audioBooks.errorMessage,
        successMessage: (state) => state.audioBooks.successMessage,
        item: (state) => state.audioBooks.item,
        refresh: (state) => state.audioBooks.refresh,
        audioItem: (state) => state.audioBooks.itemAudio,
      }),
    },
    watch: {
      errorMessage: function () {
        if (!this.errorMessage) return;
        this.baseAlert({
          icon: 'error',
          title: 'Error',
          text: `${this.errorMessage}`,
        });
      },
      successMessage: function () {
        if (!this.successMessage) return;
        this.baseAlert({
          icon: 'success',
          title: 'Success',
          text: `${this.successMessage}`,
        });
      },
      item: function () {
        if (!Object.keys(this.item).length) return;
        this.item_status_item = this.item.item_status;
        this.setEditAudioBook();
      },
      refresh: function () {
        if (!this.refresh) return;
        this.fetchAudioBookById({ id: this.item_id });
      },
      file_chapter: function () {
        if (!this.file_chapter) {
          this.file_chapter_view = '';
        } else {
          this.file_chapter_view = URL.createObjectURL(this.file_chapter);
        }
      }
    },
    created() {
      let id = this.$route.params.id;
      this.item_id = id;
      this.fetchAudioBookById({ id });
    },
    methods: {
    ...mapActions("audioBooks", ["postNewChapter", "updateChapter",  "fetchAudioBookById", "deleteChapter","updateStatusItems", "streamAudio", "getUrlStreamAudio", "getCsrfStreamAudio"]),
      onSubmitChapter(event) {
        event.preventDefault();
        const payload = this.chapter;
        if(this.file_chapter) {
          const fileSize = this.file_chapter.size;
          if (fileSize>157286400) {
            return    this.baseAlert({
              icon: 'error',
              title: 'Error',
              text: 'File Must Be Smaller than 150 MB',
            });
          }
          const fileName = this.file_chapter.name;
          if (!fileName.endsWith(".mp3")) {
            return    this.baseAlert({
              icon: 'error',
              title: 'Error',
              text: 'Should Be MP 3 Format',
            });
          }
          payload.file_chapter = this.file_chapter;
        }
        payload.file_count = 1;
        payload.item_id = this.item_id;
        if (this.chapter.is_edit) {
          this.updateChapter(payload)
        }
        else {
          this.postNewChapter(payload);
        }
        this.$bvModal.hide('modal-edit-chapter');
      },
      async playAudio(audio) {
        this.chapter.header = audio.title;
        this.chapter.chapter = audio.chapter;
        const payload = {
          id:audio.id,
        }
        const data = await this.streamAudio(payload);
        if(!data.result) {
          return this.baseAlert({
            icon:'error',
            title: 'Error',
            text: `${data.message}`,
          });
        }
        let music = data.response.data.data.content;
        const arr = new Uint8Array(music.data);
        const blob = new Blob([arr], { type: 'audio/mpeg' });
        const fileMusic = new File([blob], 'Audio Test', { type: 'audio/mpeg' });
        this.file_chapter = fileMusic;
        this.titleModal =` ${audio.title} Chapter (${audio.chapter})`
        this.$bvModal.show('modal-play-chapter');
      },
      async playAudioFromUrl(audio) {
        this.chapter.header = audio.title;
        this.chapter.chapter = audio.chapter;
        let payload = {
          id:audio.id,
        }
        const csrf = await this.getCsrfStreamAudio(payload);
        if (csrf.result) {
          payload.csrfToken = csrf.response.data;
          const fileUrl = await this.getUrlStreamAudio(payload);
          this.file_chapter_url = fileUrl;
          this.titleModal =` ${audio.title} Chapter (${audio.chapter})`
          this.$bvModal.show(`modal-play-audio-${audio.id}`);
        } else {
          const icon = 'error';
          this.baseAlert({
            icon: icon,
            title: icon,
            text: `${csrf.message}`,
          });
        }
      },
      async closeModalAudioplayer(audioId) {
        this.$bvModal.hide(`modal-play-audio-${audioId}`);
      },
      async updateStatus() {
        let icon = null;
        const update = await this.updateStatusItems({id:this.item_id});
        if(update.result == true){
          icon = 'success';
          this.fetchAudioBookById({ id:this.item_id });
        } else {
          icon = 'error';
        }   
        this.baseAlert({
          icon,
          title: icon,
          text: `${update.message}`,
        });
      },
      baseAlert(data) {
        this.$swal({
          icon: data.icon,
          title: data.title,
          text: data.text,
        })
      },
      messageAlert(icon, title, timer = 3000) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon,
          title,
          showConfirmButton: false,
          timer,
          timerProgressBar: true,
        });
      },
  
      setEditAudioBook() {
        const data = this.item;
        const audio = this.audioItem;
        if(!audio) {
          return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: 'Data Not Found',
        });
        }
        let audiobooks = [...audio];
        audiobooks.sort((a, b) => a.chapter > b.chapter ? 1 : -1);
        this.form.display_name = data.name;
        this.form.release_date = moment(data.release_date).toDate();
        this.form.release_schedule = moment(data.release_schedule).toDate();
        this.form.item_status = data.item_status;
        this.form.is_active = data.is_active;
        this.audiobooks_chapter = audiobooks
          .map((val) => {
            const sample = val.detailAudio?.is_sample ? true : false;
            const size = this.$options.filters.convertSize(val.file_size);
            const maps = {
              id: val.id,
              chapter: val.chapter,
              title: val.title,
              duration: val.file_time,
              isActive: val.is_active,
              showSample: sample,
              size: isNaN(size) ? 0 : `${size} MB`,
              created: moment(val.created).format('DD-MM-YYYY'),
            };
            if(this.form.item_status == constant.ITEM_STATUS["Ready for Upload"]) {
              maps.actions = val;
            }
            return maps;
          });
      },
  
      addChapter() {
        if(this.item_status_item == 'ready for upload') {
          this.chapter = {
          header: "Add",
          is_edit: false,
          chapter: this.audiobooks_chapter.length + 1,
          title: null,
          is_sample: false,
          minimum_level: 0,
          time_from: 0,
          time_to: 0,
          current_size: null,
        };
        this.file_chapter = null;
        this.titleModal =` ${this.chapter.header} Chapter (${this.chapter.chapter}}`
        this.$bvModal.show('modal-edit-chapter');

        }
        else {
          this.baseAlert({
            icon: 'error',
            title: 'Error Upload',
            text: 'Please Update Status to Ready For Upload',
          });
        }
      },
      bulkChapter() {
        if(this.item_status_item == 'ready for upload') {
          return this.$router.push({
            path: `/items/bulk/uploadAudio`,
          });
        }
        else {
          return this.baseAlert({
            icon: 'error',
            title: 'Error Upload',
            text: 'Please Update Status to Ready For Upload',
          });
        }
      },
  
      editChapter(data) {
        if (this.item_status_item == 'ready for upload') {
          this.chapter.is_edit = true;
          this.chapter.header =  "Edit",
          this.chapter.chapter = data.actions?.chapter;
          this.chapter.title = data.actions?.title;
          this.chapter.is_sample = data.actions?.detailAudio?.is_sample;
          this.chapter.time_from = data.actions?.detailAudio?.duration_from / 6000;
          this.chapter.time_to = data.actions?.detailAudio?.duration_to / 6000;
          this.chapter.current_size = data.size;
          this.chapter.current_size = data.size;
          this.file_chapter = null;
          this.$bvModal.show('modal-edit-chapter');
        } else {
          this.baseAlert({
            icon: 'error',
            title: 'Error Edit',
            text: 'Please Update Status to Ready For Upload',
          });
        }
      },
  
      removeChapter(data) {
        let icon = null;
        if (this.item_status_item == 'ready for upload') {
          this.$swal
          .fire({
            title: "Are you sure?",
            text: `Delete chapter ${data.chapter}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const deleteAction = await this.deleteChapter({item_id: this.item_id, chapter: data.chapter});
              if(deleteAction.result==true) {
                icon = 'Success';
              } else {
                icon = 'Error';
              }
              this.baseAlert({
                icon: icon.toLowerCase(),
                title: `${icon} Delete`,
                text: `${deleteAction.message}`,
              });   
              this.fetchAudioBookById({ id:this.item_id });
            }
          });
        } else {
          this.baseAlert({
            icon: 'error',
            title: 'Error Delete',
            text: 'Please Update Status to Ready For Upload',
          });
        }
      },
    },
    filters: {
      convertSize(value) {
        const size = parseFloat(value);
        return (size / 1000 ** 2).toFixed(1)
      },
      msToMinutes(value) {
        return value / 6000;
      }
    }
  };
  </script>
  
  <style lang="css">
  .create-new {
    font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
  }
  
  </style>
  